export default class TournamentsPostModel {
    constructor(item) {
        this._setName(item);
        this._setNameRu(item);
        this._setPrize(item);
        this._setPrizeRu(item);
        this._setStart(item);
        this._setEnd(item);
        this._setType(item);
        this._setImage(item);
        this._setCategory(item);
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }

    /**
     * set prize
     * @param prize
     * @private
     */
    _setPrize({prize}) {
        this.prizePool = prize;
    }

    /**
     * set prize ru
     * @param prize_ru
     * @private
     */
    _setPrizeRu({prize_ru}) {
        this.prizePool_ru = prize_ru;
    }

    /**
     * set start
     * @param start
     * @private
     */
    _setStart({start}) {
        this.startDate = start;
    }

    /**
     * set end
     * @param end
     * @private
     */
    _setEnd({end}) {
        this.endDate = end;
    }

    /**
     * set type
     * @param type
     * @private
     */
    _setType({type}) {
        if (type?.id) {
            this.tournamentTypeId = type.id;
        }
        else {
            this.tournamentTypeId = type;
        }
    }

    /**
     * set category
     * @param category
     * @private
     */
    _setCategory({category}) {
        if (category?.id) {
            this.categoryId = category.id;
        }
        else {
            this.categoryId = category;
        }
    }

    /**
     * set image
     * @param image
     * @private
     */
    _setImage({image}) {
        this.photo = image;
    }
}
