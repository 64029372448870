import imageInput from '@/mixins/image-input.js';

import {mapState} from "vuex";
import TournamentsPostModel from "../tournaments/models/tournaments-post.model";

export default {
    name: "TournamentAdd",
    data() {
        return {
            item: {},
            menu: false
        }
    },
    methods: {
        approve() {

            let data = new TournamentsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("tournaments/setItem", formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                }
            });
        }
    },
    computed: {
        ...mapState({
            tournamentTypes: (state) => state.tournaments.tournamentTypes,
            categories: (state) => state.categories.items,
        }),
    },

    mixins: [imageInput],
};
